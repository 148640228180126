import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <App />
  // <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
  //   <h1 className='text-center'>Work In Progress...</h1>
  //   <div className="">
  //     <h3>Will back soon!</h3>
  //   </div>
  // </div>
);

