import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    art_type: [],
    art_products: {},
    my_fav_arts: {},
    categories: {},
    slideshowData: {},
    embroiderArts: [],
    userArts: []
}

export const artSlice = createSlice({
    name: 'arts',
    initialState,
    reducers: {
        artTypeStore: (state, action) => {
            state.art_type = action.payload
        },
        artDataStore: (state, action) => {
            let payload = action.payload

            let filterArr = payload?.data?.filter((i) => !i?.media_url?.includes('https'))
            payload.data = filterArr
            state.art_products = payload
        },
        myFavStore: (state, action) => {
            state.my_fav_arts = action.payload?.data
        },
        categoryStore: (state, action) => {
            state.categories = action.payload?.category
        },
        slideshowStore: (state, action) => {
            state.slideshowData = action.payload
        },
        embroideryArtStore: (state, action) => {
            state.embroiderArts = action.payload?.data
        },
        userArtWorksStore: (state, action) => {
            state.userArts = action.payload
        },
        emptyArtData:(state,action)=>{
            return initialState
        }
    }
})

export const { artTypeStore, artDataStore, myFavStore, categoryStore, slideshowStore, embroideryArtStore, userArtWorksStore,emptyArtData} = artSlice.actions
export default artSlice.reducer