import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducer/AuthReducer";
import ArtReducer from "./reducer/ArtReducer";
import Common from "./reducer/Common";

const persistConfig = {
    key: 'root', // The key to use in localStorage
    storage, // Use the storage engine (e.g., localStorage)
    whitelist:['auth','arts','common']
};


const reducers = combineReducers({
    auth:AuthReducer,
    arts:ArtReducer,
    common:Common,
})
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
})
const persistor = persistStore(store);

export { store, persistor };