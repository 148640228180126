import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    terms_data: {},
    privacy_policy_data: {},
    shipping_data: {},
    faq_data: [],
    cart_data: [],
    cart_count: [],
    notification_list: [],
    playgroundData: [],
    homeSearchValue: ""
}

export const artSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        termsDataStore: (state, action) => {
            state.terms_data = action.payload?.data
        },
        privacyDataStore: (state, action) => {
            state.privacy_policy_data = action.payload?.data
        },
        shippingDataStore: (state, action) => {
            state.shipping_data = action.payload?.data
        },
        faqListStore: (state, action) => {
            state.faq_data = action.payload?.data
        },
        cartDataStore: (state, action) => {
            state.cart_data = action.payload
        },
        cartCountStore: (state, action) => {
            state.cart_count = action.payload
        },
        notificationsStore: (state, action) => {
            state.notification_list = action.payload
        },
        playgroundDataStore: (state, action) => {
            state.playgroundData = action.payload
        },
        homeSearchValueStore: (state, action) => {
            state.homeSearchValue=action.payload
        },
        emptyCommonData: (state, action) => {
            return initialState
        }
    }
})

export const { termsDataStore, privacyDataStore, shippingDataStore, faqListStore, cartDataStore, notificationsStore, playgroundDataStore, emptyCommonData,homeSearchValueStore, cartCountStore } = artSlice.actions
export default artSlice.reducer