import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    authData: {},
    myData: {},
    beArtistData: {}
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        AuthData: (state, action) => {
            state.authData = action.payload?.data
        },
        myDataStore: (state, action) => {
            state.myData = action.payload?.data
        },
        beArtistStatusData: (state, action) => {
            state.beArtistData = action.payload
        },
        logoutAction: (state, action) => {
            return initialState
        }
    }
})

export const { AuthData, myDataStore, beArtistStatusData, logoutAction } = authSlice.actions
export default authSlice.reducer