import './App.css'
import './responsive.css'
import { Suspense, lazy, useEffect } from 'react'
import logo from "./assets/splash_logo.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { getFcmToken, onMessageListener } from './firebase';
import { toast } from "react-toastify"
const AppRouter = lazy(() => import('./routes'))
const App = () => {
  const fcmToken = async () => {
    let fcmToken = await getFcmToken()
    localStorage.setItem('fcmToken', fcmToken)
  }
  useEffect(() => {
    onMessageListener((noti) => {
      toast.success(<span>{noti.notification?.title}<br />{noti.notification?.body}</span>)
    });
  }, [])
  useEffect(() => {
    fcmToken()
  }, [])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div className="loading lazy_loading">
          <img src={logo} alt="logo" />
        </div>}>
          <AppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  )
}
export default App