// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDVCzRlWgcfrcH33YQG4on3NyTsnX4xzzA",
    authDomain: "topcouture-af6d4.firebaseapp.com",
    projectId: "topcouture-af6d4",
    storageBucket: "topcouture-af6d4.appspot.com",
    messagingSenderId: "1004050010083",
    appId: "1:1004050010083:web:fabb210190ed1c5985dad6",
    measurementId: "G-0PDRPSEPM5"
  };
let firbaseVapidKey = "BAbXzQRZ-uPl03ZEvUnegGDNE6E7alNLnq0EqJRVlSluDLn8RGNHb0nVf1SktdIPhptbCI0muSrMXvjaShXmY9o"
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const firebaseAuth = getAuth(app)
// const messaging = getMessaging(app);
export const getFcmToken = async () => {
  let currentToken = '';
  try {
      currentToken = await getToken(messaging, { vapidKey: firbaseVapidKey });
  } catch (error) {
    // window.location.reload()
      console.log('An error occurred while retrieving token ::::: ', error);
  }
  return currentToken;
};

let messaging = null
if (navigator.vendor !== 'Apple Computer, Inc.') {
    messaging = new getMessaging(app);
} else {
    console.log("Running on Safari")
}

// RESPONSOBLE FOR FIRING NOTIFICATION
export const onMessageListener = (navRec) => {
  new Promise((resolve, reject) => {
        onMessage(messaging, (payload) => {
            navRec(payload);
            resolve(payload);
        });
    });
}